<template>
   <div class="footer">
        <br>
        <div style="width:300px;margin:0 auto; padding:20px 0;">
          <a href="https://beian.miit.gov.cn/">ICP备案号 豫ICP备2022027482号-1</a>
          <br>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010602503544" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <img src="../assets/备案图标.png" style="float:left;"/>
            <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">
              渝公网安备 50010602503544号
            </p>
          </a>
        </div>
   </div>
</template>

<script>

export default {
  name: 'Footer'
}
</script>
<style scoped lang="less">
.footer {
    text-align: center;
    a {
        color: rgb(157, 157, 157);
    }
}

</style>
