import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
// 将vuex中所有的数据存储起来
import createpersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  // 找到插件的位置，在index.js中创建的stroe对象
  plugins: [createpersistedState()],
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user
  }
})
