import axios from 'axios'

export default {
  namespaced: true,
  state: {
    token: '',
    userInfo: {}
  },
  mutations: {
    updateToken (state, token) {
      state.token = token
    },
    updateUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }

  },
  actions: {
    async getUserInfo (context) {
      // eslint-disable-next-line no-unused-vars
      const { data: res } = await axios.get('/users/userinfo')
      context.commit('updateUserInfo', res.data)
    }
  },
  getters: {}
}
