<template>
  <div class="header">
  <div class="body">
    <!-- logo -->
    <div class="logo" @click="$router.push('/')"></div>
    <!-- 菜单 -->
    <el-menu
    router
    :default-active="$route.path"
    mode="horizontal"
    class="el-menu-vertical-demo menu_mobile"
    background-color="#fff"
    text-color="#0d8c0d"
    active-text-color="#0d8c0d">
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-menu"></i>
              菜单
            </template>
            <el-menu-item  index="/index"><i class="el-icon-s-home">首页</i></el-menu-item>
            <el-menu-item  index="/photo"><i class="el-icon-camera">图像</i></el-menu-item>
            <el-menu-item  index="/article"><i class="el-icon-tickets">文字</i></el-menu-item>
            <!-- <el-menu-item  index="/game"><i class="el-icon-lollipop">杂技</i></el-menu-item> -->
            <el-menu-item  index="/about"><i class="el-icon-files">关于</i></el-menu-item>
          </el-submenu>
          <el-submenu v-if="userInfo.username" index="2">
              <template slot="title">
                  <img
                  :src="userInfo.avatar"
                  :style="{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '50%'}">
                  {{ userInfo.nickname || userInfo.username }}
              </template>
                  <el-menu-item  :index="'/home?author=' + userInfo.username"><i class="el-icon-s-home">主页</i></el-menu-item>
                  <el-menu-item index="/my"><i class="el-icon-user-solid">我的</i></el-menu-item>
                  <el-menu-item index="/pub"><i class="el-icon-plus">发布</i></el-menu-item>
                  <el-menu-item index="" @click="logout"><i class="el-icon-top-right">登出</i></el-menu-item>
          </el-submenu>
          <el-menu-item v-else index="/login"><i class="el-icon-user"></i>登录</el-menu-item>
      </el-menu>
      <el-menu
      router
      :default-active="$route.path"
      mode="horizontal"
      class="el-menu-vertical-demo menu_pc"
      background-color="#fff"
      text-color="#0d8c0d"
      active-text-color="#0d8c0d">
            <el-menu-item  index="/index"><i class="el-icon-s-home">首页</i></el-menu-item>
            <el-menu-item  index="/photo"><i class="el-icon-camera">图像</i></el-menu-item>
            <el-menu-item  index="/article"><i class="el-icon-tickets">文字</i></el-menu-item>
            <!-- <el-menu-item  index="/game"><i class="el-icon-lollipop">杂技</i></el-menu-item> -->
            <el-menu-item  index="/about"><i class="el-icon-files">关于</i></el-menu-item>
            <el-submenu v-if="userInfo.username" index="2">
              <template slot="title">
                <img
                :src="userInfo.avatar"
                :style="{ width: '40px', height: '40px', objectFit: 'cover', borderRadius: '50%'}">
                {{ userInfo.nickname || userInfo.username }}
              </template>
                <el-menu-item  :index="'/home?author=' + userInfo.username"><i class="el-icon-s-home">主页</i></el-menu-item>
                <el-menu-item index="/my"><i class="el-icon-user-solid">我的</i></el-menu-item>
                <el-menu-item index="/pub"><i class="el-icon-plus">发布</i></el-menu-item>
                <el-menu-item index="" @click="logout"><i class="el-icon-top-right">登出</i></el-menu-item>
            </el-submenu>
            <el-menu-item v-else index="/login"><i class="el-icon-user"></i>登录</el-menu-item>
      </el-menu>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Hearer',
  data () {
    return {
    }
  },
  methods: {
    logout () {
      this.$confirm('此操作将退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 清除token
        this.$store.commit('user/updateToken', '')
        this.$store.commit('user/updateUserInfo', '')
        this.$message({
          type: 'success',
          message: '退出成功'
        })
        this.$router.push('/login')
      }).catch(e => e)
    }
  },
  computed: {
    ...mapState('user', ['userInfo'])
  }
}
</script>

<style scoped lang="less">
.header {
  width: 100%;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  .body {
    padding: 0 10px ;
    display: flex;
    justify-content: space-between;
    .logo {
      cursor: pointer;
      background: url(../assets/logo.png) no-repeat;
      background-size: contain;
      background-position: center center;
      max-width: 200px;
      min-width: 100px;
      min-height: 40px;
      max-height: 60px;
    }

    .menu_pc {
      /deep/ .el-submenu__title {
        font-size: 18px;
        padding: 0 5px;
      }
    }
    .menu_mobile {
      display: none;
    }
    .el-menu--horizontal>.el-menu-item {
      min-width: 90px;
      font-size: 18px;
    }
  }

}

// 自适应
@media screen and (max-width: 768px) {
  .menu_pc {
    display: none;
  }
  .menu_mobile {
    display: block !important;
  }
}
</style>
