import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// 导入首页、登录注册页面
// import Main from '@/views/Main.vue'
// import Index from '@/views/Index/Index.vue'
// import Reg from '@/views/Reg/Reg.vue'
// import Login from '@/views/Login/Login.vue'
// // 导入my相关页面
// import My from '@/views/My/My.vue'
// import UserInfo from '@/views/My/User/UserInfo.vue'
// import UserAvatar from '@/views/My/User/UserAvatar.vue'
// import UserPwd from '@/views/My/User/UserPwd.vue'
// import ArtCate from '@/views/My/Aritcle/ArtCate.vue'
// import ArtList from '@/views/My/Aritcle/ArtList.vue'
// import PhotoList from '@/views/My/Photo/PhotoList.vue'
// import About from '@/views/About/About.vue'
// // 导入板块页
// import Article from '@/views/Article/Article.vue'
// import Photo from '@/views/Photo/Photo.vue'
// import Game from '@/views/Game/Game.vue'
// import Home from '@/views/Home/Home.vue'
// // 导入预览页
// import PreviewArticle from '@/views/Preview/PreviewArticle.vue'
// import PreviewPhoto from '@/views/Preview/PreviewPhoto.vue'
// // 导入发布页
// import Pub from '@/views/Pub/Pub.vue'
// import PubArt from '@/views/Pub/PubArt.vue'
// import PubPhoto from '@/views/Pub/PubPhoto.vue'

const Main = () => import('@/views/Main.vue')
const Index = () => import('@/views/Index/Index.vue')
const Reg = () => import('@/views/Reg/Reg.vue')
const Register = () => import('@/views/Reg/Register.vue')
const Login = () => import('@/views/Login/Login.vue')

const My = () => import('@/views/My/My.vue')
const UserInfo = () => import('@/views/My/User/UserInfo.vue')
const UserAvatar = () => import('@/views/My/User/UserAvatar.vue')
const UserPwd = () => import('@/views/My/User/UserPwd.vue')
const ArtCate = () => import('@/views/My/Aritcle/ArtCate.vue')
const ArtList = () => import('@/views/My/Aritcle/ArtList.vue')
const PhotoList = () => import('@/views/My/Photo/PhotoList.vue')

const About = () => import('@/views/About/About.vue')
const Article = () => import('@/views/Article/Article.vue')
const Photo = () => import('@/views/Photo/Photo.vue')
const Game = () => import('@/views/Game/Game.vue')

const Home = () => import('@/views/Home/Home.vue')
const HomeArticle = () => import('@/views/Home/HomeArticle.vue')
const HomePhoto = () => import('@/views/Home/HomePhoto.vue')

const PreviewArticle = () => import('@/views/Preview/PreviewArticle.vue')
const PreviewPhoto = () => import('@/views/Preview/PreviewPhoto.vue')

const Pub = () => import('@/views/Pub/Pub.vue')
const PubArt = () => import('@/views/Pub/PubArt.vue')
const PubPhoto = () => import('@/views/Pub/PubPhoto.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    redirect: '/index',
    meta: {
      title: 'circusfloor_我的摄影作品'
    },
    children: [
      {
        path: 'index',
        component: Index,
        meta: {
          title: 'circusfloor_我的摄影作品'
        }
      },
      { path: 'reg', component: Reg },
      { path: 'register', component: Register },
      { path: 'login', component: Login },
      { path: 'article', component: Article },
      { path: 'photo', component: Photo },
      { path: 'game', component: Game },
      { path: 'about', component: About },
      { path: 'preview/article', component: PreviewArticle },
      { path: 'preview/photo', component: PreviewPhoto },
      {
        path: 'home',
        component: Home,
        redirect: '/home/photo',
        children: [
          {
            path: 'article',
            component: HomeArticle
          },
          {
            path: 'photo',
            component: HomePhoto
          }
        ]
      },
      {
        path: 'pub',
        component: Pub,
        redirect: 'pub/photo',
        children: [
          {
            path: 'art',
            component: PubArt
          },
          {
            path: 'photo',
            component: PubPhoto
          }
        ]
      },
      {
        path: 'my',
        component: My,
        redirect: 'my/user-info',
        children: [
          {
            path: 'user-info',
            component: UserInfo
          },
          {
            path: 'user-avatar',
            component: UserAvatar
          },
          {
            path: 'user-pwd',
            component: UserPwd
          },
          {
            path: 'art-cate',
            component: ArtCate
          },
          {
            path: 'art-list',
            component: ArtList
          },
          {
            path: 'photo-list',
            component: PhotoList
          }
        ]
      }
    ]
  }

]

const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savePosition) {
    // 新页面加载位置
    return {
      y: 0
    }
  }
})

// 注册全局前置守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'circusfloor'
  if (!store.state.user.token && to.path !== '/login' && to.path !== '/reg') {
    if (to.path === '/my/user-info' || to.path === '/pub/art' || to.path === '/pub/photo') {
      Vue.prototype.$message.error('请先登录')
      return next('/login')
    }
  }
  // sessionStorage.setItem(from.path + 'scrollTop', document.documentElement.scrollTop || document.body.scrollTop)
  next()
})
export default router
