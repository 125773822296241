// 检查点赞cookie，如果没有则添加，有则反馈已点赞
import cookie from './cookie'
import { Message } from 'element-ui'
import Vue from 'vue'
import axios from 'axios'
import store from '../store'

function like (e, id, index, type) {
  const username = store.state.user.userInfo.username || ''
  // 点赞请求
  async function likeRequest (id, type) {
    const { data: res } = await axios.post(`/${type}/addlike`, { id, username })
    if (res.code !== 200) return Message.warning(res.error)
    Message.success(res.message)
    e.target.className = 'el-icon-star-on'
    e.target.innerText++
  }

  // 文章或者图像点赞
  const Likes = cookie.getCookie(`${type}Likes`)
  const key = [`${type}Likes`]
  const cookieobj = {}
  if (Likes !== null) {
    const ary = Likes.split(',')
    if (ary.indexOf(String(id)) !== -1) {
      Message.warning('已点过赞了！')
    } else {
      likeRequest(id, type)
      ary.push(id)
      cookieobj[key[0]] = ary.join(',')
      cookie.setCookie(cookieobj, 7)
    }
  } else {
    likeRequest(id, type)
    cookieobj[key[0]] = id
    cookie.setCookie(cookieobj, 7)
  }
}

// 将文章url复制到剪贴板
function share (id, type) {
  const url = 'https://' + window.location.host + `/#/preview/${type}?data=${id}&type=${type}`
  Vue.prototype.$copyText(url).then(
    e => { Message.success('已复制链接到剪贴板') },
    e => { Message.warning('复制链接失败') }
  )
}

export default {
  like,
  share
}
