import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入axios
import axios from 'axios'
// 引入全局组件
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import Card from '@/components/Card.vue'
// 导入全局样式表
import '@/assets/global.less'
// import 'element-ui/lib/theme-chalk/display.css'
// 导入vue-quill-editor
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
// 导入代码块高亮样式
import 'highlight.js/styles/vs2015.css'
// 导入vue-clipboard2
import VueClipBoard from 'vue-clipboard2'
// 引入自定义cookie方法
import cookie from '@/util/cookie'
// 引入自定义interactive方法
import interactive from '@/util/interactive'
// 引入matomo
// import VueMatomo from 'vue-matomo'

// 修改element-ui message组件全局配置
const messages = ['success', 'warning', 'info', 'error']

messages.forEach(type => {
  ElementUI.Message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options
      }
      // 默认配置
      options.offset = 60
      options.duration = 2000
      options.showClose = true
    }
    options.type = type
    return ElementUI.Message(options)
  }
})

// 设置cookie方法
Vue.prototype.$cookie = cookie
// 设置interactive方法
Vue.prototype.$interactive = interactive
// 注册插件
Vue.use(VueQuillEditor)
Vue.use(VueClipBoard)
Vue.config.productionTip = false
Vue.use(ElementUI)
// 注册全局组件
Vue.component('Header', Header)
Vue.component('Footer', Footer)
Vue.component('Card', Card)

// 设置 axios
// axios.defaults.baseURL = 'http://192.168.1.6:8000'
axios.defaults.baseURL = 'https://circusfloor.club/api/'
Vue.prototype.$http = axios

// 设置matomo
// Vue.use(VueMatomo, {
//   host: 'https://circus.ink', // 这里配置你自己的piwik服务器地址和网站ID
//   siteId: 1,
//   // 根据router自动注册
//   router: router,
//   // // 是否需要在发送追踪信息之前请求许可
//   // // 默认false
//   requireConsent: false,
//   enableLinkTracking: true,
//   // // 是否追踪初始页面
//   // // 默认true
//   trackInitialView: false,
//   // // 最终的追踪js文件名
//   // // 默认 'piwik'
//   trackerFileName: 'matomo',
//   debug: false
// })

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  config.headers.Authorization = store.state.user.token
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (response.data.code === 401) {
    // 清空token
    store.commit('user/updateToken', '')
    store.commit('user/updateUserInfo', '')
    // 跳转到登录页面
    router.push('/login')
    // 提醒用户
    Vue.prototype.$message.warning('登录状态过期，请重新登录')
  }
  return response
}, function (error) {
  // 对响应错误做点什么er4
  return Promise.reject(error)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
